// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-blog-post-template-js": () => import("./../src/templates/BlogPostTemplate.js" /* webpackChunkName: "component---src-templates-blog-post-template-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-resources-js": () => import("./../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-resources-books-js": () => import("./../src/pages/resources/books.js" /* webpackChunkName: "component---src-pages-resources-books-js" */),
  "component---src-pages-resources-designers-js": () => import("./../src/pages/resources/designers.js" /* webpackChunkName: "component---src-pages-resources-designers-js" */),
  "component---src-pages-resources-online-resources-js": () => import("./../src/pages/resources/online-resources.js" /* webpackChunkName: "component---src-pages-resources-online-resources-js" */),
  "component---src-pages-thank-you-for-signing-up-js": () => import("./../src/pages/thank-you-for-signing-up.js" /* webpackChunkName: "component---src-pages-thank-you-for-signing-up-js" */)
}

